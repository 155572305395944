import React, {useEffect} from 'react';

import classes from './ToastMessage.module.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ToastMessage = ( { message  } ) => {
  return (
    <div className={classes.toastBg}>
    <div className={classes.toastContainer}>
      <CheckCircleIcon />
      <div className={classes.toastMessage}>
       {message}
      </div>
    </div>
    </div>
  );
};

export default ToastMessage;
