import React, { useState, useContext, useMemo, useEffect } from 'react';
import classes from './ListOrganise.module.css';

import AddIcon from '@mui/icons-material/Add';
import Button from '../UI/Button';

import Tab from '../Modals/Tab';
import Modal from '../Modals/Modal';
import Share from '../Share/Share';
import Alert from '../Modals/Alert';
import ToastMessage from '../Modals/ToastMessage';
import HireBorrow from '../Modals/HireBorrow';

import { doc, updateDoc, addDoc, where, arrayUnion, getDocs, query, collection, Timestamp } from 'firebase/firestore';
import { auth, db, METHODS } from '../../config';

import { UserContext } from '../../store/user-context';

import { DEFAULTVALUES } from '../../config';

import ReactGA from 'react-ga4';
import pluralize from 'pluralize';

import EditSubItem from '../Forms/EditSubItem';
import EditItemNotes from '../Forms/EditItemNotes';
import ListAddOrganise from './ListAddOrganise';
import ListItemAdd from './ListItemAdd';
import Avatar from '../Group/Avatar';
import Protip from './Protip';
import parse from 'html-react-parser';
import LaunchIcon from '@mui/icons-material/Launch';
import ClearIcon from '@mui/icons-material/Clear';


import avatar1 from '../../assets/avatars/buy-avatar.svg';
import avatar2 from '../../assets/avatars/bring-avatar.svg';
import avatar3 from '../../assets/avatars/borrow-avatar.svg';
import avatar4 from '../../assets/avatars/hire-avatar.svg';

const ListOrganise = ({ group, item, editGroupHandler, filterMembers, name }) => {
    //organise item e.g. organise all tents
    //console.log(filterMembers)

    const user = auth.currentUser;
    const UserCtx = useContext(UserContext);
    const festival = UserCtx.festival;
    const defaultItems = UserCtx.itemInfo;
    const [working, setWorking] = useState(false);
    const [alertMessage, setAlertMessage] = useState();
    const [toastMessage, setToastMessage] = useState();
    const [hireBorrowModal, setHireBorrowModal] = useState(false);
    const hireUrl = festival?.hireurl ? festival?.hireurl : 'https://www.overnightadventures.com/equipment'

    const [showOrganise, setShowOrganise] = useState(false);
    const [itemInfo, setItemInfo] = useState();
    const [itemStatus, setItemStatus] = useState();

    const [numberTotalItems, setNumberTotalItems] = useState();
    const [numberItemsHave, setNumberItemsHave] = useState();
    const [numberItemsNeed, setNumberItemsNeed] = useState();
    const [tabHeading, setTabHeading] = useState();
    const [itemNamePlural, setItemNamePlural] = useState();
    

    //get data around how this item is being organised
    const organise = item.organise;
    //if (item.name==="Tent") console.log(item)
    const filteredItems = organise.filter(org => filterMembers?.includes(org.assigned));
    const methods = filteredItems.map(item => item.method); //methods assigned to this item for anyone in the filtered list 
    const assignees = filteredItems.map(item => item.assigned); //members assigned to this item including anyone in the filtered list 
    //if (item?.name === "Plates") console.log(organise);
    /* cconst methods = []; 
    const assignees = []; 
    organise.forEach( org => {
        if (filterMembers?.includes(org.assigned)) {
            methods.push(org.method);
            assignees.push(org.assigned);
        }
    })*/

    const itemMethods = methods.filter(method => method && (method!== 'Borrow' && method!== 'Hire' && method!== 'Buy')); //get rid of null values and options from previous iteration
    const itemAssignees = assignees.filter(assignee => assignee && assignee !== 'unassigned'); //get rid of null values
    
    let uniqueMethods = [...new Set(itemMethods)];
    let uniqueAssignees = [...new Set(itemAssignees)];
    //if (item?.name === "Tent") console.log(itemAssignees, uniqueMethods, itemInfo );

    const recordHireClick = async () => {
        window.open(hireUrl, 'cbHire');
        if (working===true) return;
        setWorking(true);
        //make a copy of the existing group items array
        const updatedItems = [...group?.items];
        //go through all organise properties for this item and update their status - no multiples yet
        updatedItems?.forEach(i => { if (i.id === item.id) { i.organise?.map( org => org.status = 'Hired')}})
        try {
            const groupRef = doc(db, 'groups', group.id);
            await updateDoc(groupRef, {
                items: updatedItems,
                lastUpdate: Timestamp.fromDate(new Date())
            });
        } catch (err) {
            alert("There was a problem updating your group.")
        }
        setWorking(false);
        const newActivity = {
            userid: user.uid,
            activity: 'hire',
            item: item?.name,
            festival: festival?.name,
            createdAt: Timestamp.fromDate(new Date())
        };
        try {
            //add new activity to log collection
            await addDoc(collection(db, "log"), newActivity);
        } catch (err) {
            //console.log(err.message);
        }
        
    }

    const resetItemHandler = async () => {
         //find which item they are editing eg tent
         const itemIndex = group.items.findIndex(i => i.id === item.id);
         //make a shallow copy of the existing group items array
         const updatedItems = [...group.items];
         //reset the organise array
         updatedItems[itemIndex].organise = [{ assigned: user.uid, checked: false }];
         updatedItems[itemIndex].status=null;
         try {
         const groupRef = doc(db, 'groups', group.id);
         await updateDoc(groupRef, {
             items: updatedItems,
             lastUpdate: Timestamp.fromDate(new Date())
         });
     } catch (err) {
         setAlertMessage({ title: `Error`, message: `There was a problem updating the item. ` + err.message });
     }

    }

    const saveItemToGroup = async (have, index) => {
        if (working) return;
        setWorking(true);
        const itemMethod = have ? 'Bring' : 'Need';

        if (!have) {
            //determine whether to show the hire / borrow modal
            const showHireBorrowModal = (itemInfo?.hireOption || itemInfo?.impact) ? true : false;
            setHireBorrowModal(showHireBorrowModal);
        }
       
        //find which item they are editing eg tent
        const itemIndex = group.items.findIndex(i => i.id === item.id);
        //make a shallow copy of the existing group items array
        const updatedItems = [...group.items];
        //check the item has organised items otherwise create the frst one
        const updatedSubItem = updatedItems[itemIndex].organise.length>0 ? 
            updatedItems[itemIndex].organise[index]:
            { assigned: user.uid, checked: false }
        //update the relevant property
        updatedSubItem.method = itemMethod;
        updatedItems[itemIndex].organise[index] = updatedSubItem;

        try {
        const groupRef = doc(db, 'groups', group.id);
        await updateDoc(groupRef, {
            items: updatedItems,
            lastUpdate: Timestamp.fromDate(new Date())
        });
        //hide message after 5 seconds
        setToastMessage( have ? 'Added to your packing list': 'Added to gear you need to get');
        setTimeout(() => { setToastMessage() }, 3000);
    } catch (err) {
        setAlertMessage({ title: `Error`, message: `There was a problem updating your group. ` + err.message });
    }

        //update user details to say they are an active organiser
        if (UserCtx.settings.activeOrganiser === false) {
        try {
            const userRef = doc(db, 'users', user.uid);
            await updateDoc(userRef, {
                activeOrganiser: true
            });
        } catch (err) {
            setAlertMessage({ title: `Error`, message: `There was a problem updating the user. ` + err.message });
        }
    }
        setWorking(false);

    }

    const removeItem = async () => {
        if (working) return;
        setWorking(true);
        //find which item they are editing eg tent
        const itemIndex = group.items.findIndex(i => i.id === item.id);
        //make a shallow copy of the existing group items array
        const updatedItems = [...group.items];
        updatedItems.splice(itemIndex, 1);
        try {
            const groupRef = doc(db, 'groups', group.id);
            await updateDoc(groupRef, {
                items: updatedItems,
                lastUpdate: Timestamp.fromDate(new Date())
            });
        } catch (err) {
            setAlertMessage({ title: `Error`, message: `There was a problem updating the item. ` + err.message });
        }
        setWorking(false);
    }

    const getItemInfo = () => {
        //get festival specific information about this item - either from festival collection or master list items if it's a custom event
        const itemName = item.name;
        //create object to get most up to date item info
        let _item = {};
        //decide which items we are going to search - for custom festivals search through the master packing list, otherwise search for festival items
        const itemInfoList = (festival) ? festival.items : defaultItems;
        
        //look for items in the list that have the same name as the current item
        _item = itemInfoList?.find(i => (i.name.toLowerCase() === itemName.toLowerCase()));

        //if there's nothing found search for alternative spellings (useful for if they have added their own item)
        if (!_item) {
            _item = itemInfoList?.find(item => {
                const akaArray = item.aka.split(',');
                akaArray.push(item.name.toLowerCase() + 's');//add plural as well
                akaArray.map(aka => aka.trim().toLowerCase());
                return akaArray.includes(itemName.toLowerCase());
            });
        }
        //console.log(itemName,item)
        let tipGeneral = _item ? _item.tip : null;
        let tipHiring = _item ? _item["tip-hiring"] : null;
        let tipBuying = _item ? _item["tip-buying"] : null;
        const hiringOption = _item ? _item.hire : false;

        //only show hiring / buying information if it's not set to the default value and it's not null       
        if (DEFAULTVALUES.includes(tipHiring)) { tipHiring = null; }
        if (DEFAULTVALUES.includes(tipBuying)) { tipBuying = null; }

        if (tipHiring) tipHiring = parse(tipHiring);
        if (tipGeneral) tipGeneral = parse(tipGeneral);
        //if (tipBuying) tipBuying = parse(tipBuying); 
               

        const itemInfo = { id: item.id, general: tipGeneral, hireOption: hiringOption, hiring: tipHiring, buying: tipBuying, impact:item.impact };
        setItemInfo(itemInfo);
    }

    

    const [itemNotifications, setItemNotifications] = useState(Array(numberTotalItems));
    const generateItemNotification = (ind, notification) => {
        const newNotifications = [...itemNotifications];
        ind > -1 ? newNotifications[ind] = notification : newNotifications.push(notification);
        setItemNotifications(newNotifications);
    }

    const sendNotificationsToFirebase = async (email, memberNotifications) => {
        if (!memberNotifications || memberNotifications.length < 1 || !email) return;
        //add notification object to user record in db
        try {
            //get target user via email (not id)
            const q = query(collection(db, "users"), where("email", "==", email));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (document) => {
                //loop through the person's notification
                memberNotifications.forEach(async (n) => {
                    await updateDoc(document.ref, {
                        //add to existing notifications in firebase
                        notifications: arrayUnion(n)
                    });
                });
            });
        } catch (err) {
            console.log(err);
        }
    }

    const submitHandler = () => {
        //if there have been any changes made to the item then group these into notifications per group member and send this info to the DB as notification
        group.members.map(member => {
            sendNotificationsToFirebase(member.email,
                itemNotifications.filter(n => n && n.assigned === member.id)
            );
        });
        //reset notifications array
        setItemNotifications(Array(numberTotalItems));
        setShowOrganise(false);
    }

    const renderOrganiseInfo = () => {

        return (
            <div 
            key='1' 
            className={`${classes.organiseIconsContainer}`}
            >

                {/* No methods have been set so show have/need buttons */}
                {uniqueMethods?.length === 0 && 
                
                <>
                 <Button style={{marginRight:10}} onClick={() => saveItemToGroup(true, 0)} > Have </Button>

                 <Button onClick={() => saveItemToGroup(false, 0)} > Need </Button>
                
                </>
                }

                {/* They have specified they will bring the item so show counters */}
                {uniqueMethods?.length > 0 && !uniqueMethods?.includes('Need') &&
                 
                 <div className={classes.numberContainer}>

                    <ListItemAdd
                        direction='down'
                        numberItems={numberItemsHave}
                        numberTotalItems={numberTotalItems}
                        group={group}
                        item={item}
                        method='Bring'
                    />

                    <div className={classes.numberText}>{numberItemsHave}</div>

                    <ListItemAdd
                        direction='up'
                        numberItems={numberItemsHave}
                        numberTotalItems={numberTotalItems}
                        group={group}
                        item={item}
                        method='Bring'
                    />

                </div>
                
                }

                {/* They have specified they need the item so show 'Need' text */}
                {uniqueMethods?.length > 0 && uniqueMethods?.includes('Need') &&
                 <Button 
                    style={{backgroundColor:'#e8e8e8'}}
                    onClick={() => {(uniqueMethods?.length > 0) && setShowOrganise(true);}} 
                    > 
                 Need </Button>
                }

            {alertMessage &&
            <Alert
                title={alertMessage.title}
                message={alertMessage.message}
                onConfirm={() => setAlertMessage()}
            />
            }

            {hireBorrowModal &&
             <HireBorrow
                item = {item}
                festival={festival}
                itemInfo={itemInfo}
                onConfirm={() => setHireBorrowModal()}
                onCancel={() => setHireBorrowModal()}
                group={group}
                />
            }

          

            </div>

        )
    }

    useEffect(() => {
        if (!item || !group) return;

        //check whether there is a status set in the item organise property - only grab the first property (not doing multiples yet),
        let status = item.organise?.find( org => org.status && org.status!=="Hired" )?.status
        const helper = (status === 'Offered') ? item.organise?.find( org => org.helper )?.helper : null;
        if (status && helper) status = `${status} by ${helper}`
        setItemStatus(status)
        
        getItemInfo();

        const organise = item.organise;
        const numberTotal = organise ? organise.filter(org => (org.method!="Buy" && org.method!=null && org.method!="Hire" && org.method!="Borrow")).length : 0; //backwards compatibility (ignore methods from previous iteration)
        setNumberTotalItems(numberTotal);
        const numberHave = organise ? organise.filter(org => org.method==="Bring").length : 0;
        setNumberItemsHave(numberHave);
        const numberNeed = organise ? organise.filter(org => org.method==="Need").length : 0;
        setNumberItemsNeed(numberNeed);
        setTabHeading(item ? numberTotal > 0 ? `${item.name} x ${numberTotal}` : `${item.name}`:'');
        setItemNamePlural(pluralize(item.name, 2));

    }, [item, group, festival, defaultItems]);

    useEffect(() => {
        if (!showOrganise) return;
        if (showOrganise === true) {
          ReactGA.send({ hitType: "pageview", page: '/organise', title: 'Organise item' });
          //console.log('sending data to GA: organise');
        }
    }, [showOrganise]);

  


    if (showOrganise && item) return (

        <Modal
            align='top'
            back
            title='Organise'
            onConfirm={() => setShowOrganise(false)}
            onCancel={() => setShowOrganise(false)}
        >

            <Tab closeHandler={() => setShowOrganise(false)} title={tabHeading} item={item} group={group} bgGradient={false}>

                <div className={classes.organiseContainer}>

                    <Protip itemInfo={itemInfo} />

                    <div className={classes.organiseSubItemsContainer}>

                    {/*Items you have section*/}
                    <div className={classes.organiseSubItemsAddContainer}>
                    <div className={classes.organiseSubItemsAddName}> 
                            {itemNamePlural} you have
                        </div>
                        <div className={classes.numberContainer}>
                        <ListItemAdd
                            direction='down'
                            numberItems={numberItemsHave}
                            numberTotalItems={numberTotalItems}
                            group={group}
                            item={item}
                            method='Bring'
                        />
                        <div className={classes.numberText}>{numberItemsHave}</div>
                        <ListItemAdd
                            direction='up'
                            numberItems={numberItemsHave}
                            numberTotalItems={numberTotalItems}
                            group={group}
                            item={item}
                            method='Bring'
                        />
                        </div>
                    </div>

                    {/*Items you need section*/}
                    <div className={classes.organiseSubItemsAddContainer}>
                    
                        <div className={classes.organiseSubItemsAddName}> 
                            {itemNamePlural} you need
                            {itemStatus && <div className={classes.itemStatus}> { numberItemsNeed>0 && ` ${itemStatus}` } </div>}
                        </div>
                        <div className={classes.numberContainer}>
                        <ListItemAdd
                            direction='down'
                            numberItems={numberItemsNeed}
                            numberTotalItems={numberTotalItems}
                            group={group}
                            item={item}
                            method='Need'
                        />
                        <div className={classes.numberText}>{numberItemsNeed}</div>
                        <ListItemAdd
                            direction='up'
                            numberItems={numberItemsNeed}
                            numberTotalItems={numberTotalItems}
                            group={group}
                            item={item}
                            method='Need'
                        />
                        </div>
                    </div>

                    {numberItemsNeed > 0 && 
                        <Share group={group} />
                    }

                    {numberItemsNeed > 0 && itemInfo?.hireOption && 
                        <div className={classes.hireButtonHolder}>
                            <Button 
                                className='primary'
                                onClick={recordHireClick}>
                                {`Hire`}
                                <LaunchIcon
                                style={{ color: "#000000", fontSize: 20, verticalAlign: 'middle', padding: '0 0 2px 5px'}}
                                />
                            </Button>
                        </div>
                      }


                       {/* {organise?.map((subitem, index) => (

                            <EditSubItem
                                item={item}
                                subitem={subitem}
                                key={index}
                                ind={index}
                                group={group}
                                generateItemNotification={generateItemNotification}
                                itemInfo={itemInfo}
                                editGroupHandler={editGroupHandler}
                                uniqueAssignees={uniqueAssignees}
                                checkedItem={""}

                            />

                        ))}

                        <div className={classes.organiseSubItemsAddContainer}>
                            <ListAddOrganise label='Add' item={item} group={group} filterMembers={filterMembers} />
                        </div>*/}

                        <EditItemNotes item={item} group={group} />
                        
                        {/*<div className={classes.callToActionContainer}>
                            <Button 
                            className='borderless'
                            onClick={()=> console.log('remove item')}
                            >
                            {`Remove item`}
                            </Button>
                        </div>*/}

                        <div className={classes.callToActionContainer}>
                            <Button 
                            className='borderless'
                            onClick={resetItemHandler}
                            >
                            {`Reset item`}
                            </Button>
                        </div>

                    </div>

                    <div className={classes.organiseFooter}>

                        <Button
                            className='primary'
                            onClick={submitHandler}
                        >
                            Done
                        </Button>

                    </div>

                </div>

            </Tab>

        </Modal>

    )


    return (
        //make clickable once they have decided whether they have or need the item
        <div className={classes.itemOrganiseContainer}>
            
            <div className={classes.checklistItemName}
                onClick={() => {(uniqueMethods?.length > 0) && setShowOrganise(true);}} 
                style={{'textDecoration': uniqueMethods?.length > 0 ? 'underline solid var(--text) 3px' : 'none'}}
                >
                <Button className='noStyle' style={{'marginRight': '10px'}} onClick={removeItem}>
                    <ClearIcon style={{ color: '#b10000' }} title='Remove item'/>
                </Button>

                {item.name ? item.name : ''} 

                { numberTotalItems > 1 && ` x${numberTotalItems}` }

                {itemInfo?.hireOption && 
                    <img 
                        className={classes.checklistItemHireIcon} 
                        src={avatar4} 
                        title='Hire available' 
                        alt='Hire available'  
                    />
                }
            
            </div>

            {renderOrganiseInfo()}

            {toastMessage &&
                <ToastMessage message={toastMessage}/>
            }

        </div>
    )

}

export default ListOrganise;